import React from "react"
import { graphql } from "gatsby"

import "../style/styles.css"

// component
import Img from "gatsby-image"
import FounderCards from "../components/founderCards"
import Layout from "../components/layout"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <section className="w-full h-200 md:h-128 sm:h-128 sm:h-128 xs:h-164 xl:-mt-12  lg:-mt-12">
        <div
          className="w-full h-200 pb-48 absolute -top-300 md:h-128 sm:h-128 overflow-hidden"
          style={{ zIndex: "-1000" }}
        >
          <Img
            className=""
            fluid={data.landing.edges[0].node.childImageSharp.fluid}
            alt="frame installation"
          />
        </div>
        <div className="container mx-auto px-5 h-full flex justify-start items-end text-gray-100">
          <div className="pb-10 xs:pb-12 flex flex-col justify-end items-start max-w-xl text-sm w-full">
            <h1 className="text-5xl">About Alphacladding</h1>
            <p className="w-full">
              Alphacladding was founded in 2014 to help provide a solution to
              the growing market of curtain wall and window wall projects. Built
              around the knowledge, experience, and teamwork of 3 business
              partners who have worked together for more than 25 years,
              Alphacladding was strategically positioned to bring a major
              solution to the table.
            </p>
            <p className="w-full">
              We excel at custom designs, and we have the capability to work
              around your needs. The quality of our work is handled and approved
              by the founders at every stage of the process. Design,
              development, manufacturing, installation, and coordination are all
              managed directly by one of our owners.
            </p>
          </div>
        </div>
      </section>
      <section className="w-full bg-green-600">
        <div className="container mx-auto px-5 pt-24 pb-12 h-full flex justify-start items-center text-white">
          <div className="">
            <h1>Why Alphacladding</h1>
            <p>
              When choosing and recommending the right façade company for a
              project, there are a number of different aspects that any building
              owner, architect, or general contractor must consider to ensure
              that they make the right choice. And when it comes to each unique
              project, these considerations span things like:
            </p>
            <p>
              <b>
                <i>
                  • Reliability • Reputation • Experience • Quality • Efficiency
                  • Communication • Value • Timing
                </i>
              </b>
            </p>
            <p>
              Alphacladding is a fully integrated Curtain Wall Company that
              provides a comprehensive design, manufacturing and installation
              services for any building owner, architect, or general contractor
              seeking a custom design or off the shelf façade system for their
              building. Whether it be recladding for an existing façade, or an
              entirely new, complex façade that completely transforms your
              building, we work hand in hand with the lead architect to create a
              work of art that serves as a true expression of individuality for
              generations to come.
            </p>
          </div>
        </div>
      </section>
      <section className="w-full">
        <div className="container mx-auto px-5 pt-12 pb-12 flex flex-col justify-start text-gray-600 text-xs">
          <div>
            <h1 className="text-gray-900">Founders</h1>
            <div>
              <p>
                Each of our three owners have their roots in the Glassalum
                International Corporation, which was one of the most prestigious
                Façade Company School’s in the world.
              </p>
              <p>
                The owners-operators of Alphacladding LLC. participate in the
                daily design, development, manufacturing, installation, and
                coordination of each phase of our projects. They bring all their
                personal experience in order to provide Owners, Architects and
                clients in general a complete vision of their project, helping
                them with technical solutions that upgrades the conception they
                have of their buildings and the best possible way to reach the
                results they expect in the project.
              </p>
              <p>
                For this very reason, we always have our clients connect with
                our owners right off the bat.
              </p>
              <p>
                • The immediate response to the Clients, Owners, Architects and
                Contractors. <br />
                • The responsibility for a work well done, and the delivery of
                results with the best quality.
                <br />
                • The exclusivity of Custom Design and versatility of
                standardized tested systems, to accomplish any project
                regardless of complexity or schedule limitations.
                <br />
                • The interaction with the Architectural Team from the very
                beginning, providing Design Assist.
                <br />
              </p>
              <p id="founders">
                The Founders have worked for a number of high-end significant
                projects throughout the cities of New York, Boston, and Miami.
              </p>
            </div>
          </div>
          <FounderCards founders={data.founders.edges} />
          <p className="text-base text-center xl:px-20 lg:px-16 mt-10 text-gray-500 font-semibold">
            When you work with Alphacladding, you develop a direct relationship
            with a company that can get things done. Design, development,
            manufacturing, installation, and coordination are all managed
            directly by the owners, according with their field of expertise.
            Each of our clients are given a direct point of contact with them to
            ensure their needs are always taken care of.
          </p>
        </div>
      </section>
      <section className="w-full bg-gray-900">
        <div className="border-b container mx-auto px-5 pt-12 pb-12 xl:px-32 lg:px-16 flex flex-col justify-start text-white  text-xs text-center">
          <div className="mb-8">
            <h1 className="">Alpha Engineering Team</h1>
            <div>
              <p>
              Alphacladding has been established as a premiere player in the field because we've worked to build our reputation around the exceptional service that we provide, the quality finished products that we produce, and the dedication that our team offers each and every day. We leverage our many years of experience, our strong background in curtain wall façades and space geometry, and sophisticated production software to help bring our clients' dreams to life.
              </p>
              <p>
              We can proudly say, when you have a team of highly skilled and dedicated people by your side, we can make sure that any facade will become a reality, whether it behaves geometrically friendly or freely, non-linearly; Consequently, we can easily execute any dream conceived on paper to please the owners and architects. We rely on our team - we rely on our decades of interaction and the wealth of knowledge and diverse experience accumulated during our years in the industry, and we rely on our dedication towards not only meeting the expectations of our clients but exceeding them too.
              </p>
              <p>
              Our leadership group has worked for a number of high-end building companies and have completed a number of significant projects throughout the cities of New York, Boston, and Miami. 
              </p>
            </div>
          </div>
          <div>
            <div>
              <p className="uppercase tracking-widest text-xs mb-2">
                At Alpha We Are
              </p>
              <h1>Family Forward</h1>
            </div>
            <p>
              Alphacladding’s team environment is second to none. The majority
              of our team members, engineers and installers, have worked in
              conjunction with our owners for more than a decade, during which
              they have overcome many obstacles to maintain or better the
              schedule. You see, we’re not just a team – we’re a family united
              under the common goal of creating awe-inspiring custom façade
              designs for our clients.
            </p>
            <div>
              <Img
                fluid={data.family.edges[0].node.childImageSharp.fluid}
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    founders: allFile(
      filter: { relativePath: { regex: "/images/founders/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
    family: allFile(filter: { relativePath: { regex: "/images/family/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    landing: allFile(
      filter: { relativePath: { regex: "/images/landing/about/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default AboutPage
