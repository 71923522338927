import React from "react"

// component
import Img from "gatsby-image"

const FounderCards = ({ founders }) => {
  return (
    <div>
      {founders.map(founder => (
        <div className="" key={founder.node.name}>
          <div
            key={founder.node.name}
            className="flex mb-6 px-6 py-8 xl:pb-0 items-center bg-gray-100 rounded-lg text-gray-800 shadow-xl border-1"
          >
            <div className="xl:flex lg:flex md:flex-none sm:flex-none xs:flex-none h-full xl:items-start lg:items-start w-full">
              <div className="xl:w-2/12 lg:w-3/12 mb-4 h-auto -mt-4 xl:m-0 lg:m-0   w-1/2">
                <Img
                  className="rounded-lg xl:rounded-b-none"
                  fluid={founder.node.childImageSharp.fluid}
                  alt={founder.node.name}
                />
              </div>
              <div className="xl:w-9/12 xl:pl-8 lg:w-full lg:pl-4">
                {founder.node.name === "juan" && (
                  <>
                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      President
                    </p>
                    <h2 className="m-0">Juan Alpizar</h2>

                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      Director of Business Operations
                    </p>
                    <p className="m-0">
                      <span className="text-gray-500 font-semibold">
                        Certified General Contractor State of Florida,
                        Bachelor’s Degree in Architecture
                      </span>
                      <br />
                      Successful entrepreneur, Juan is a highly talented, and
                      accomplished Engineering Designer, with solid skills and
                      experience in Project Management, Construction
                      Development, Quality Control, and execution of custom
                      facades. Juan is also in charge of estimating and
                      negotiations of contracts. With more than 32 years in
                      construction and 22 years of experience in Curtain Wall
                      and Window Wall Facades, Juan was part of the key people
                      of many highly recognized facades companies located in
                      South Florida and former owner of Gamma North America.
                    </p>
                  </>
                )}
                {founder.node.name === "mike" && (
                  <>
                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      Vice President
                    </p>
                    <h2 className="m-0">Mike DeHarde</h2>

                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      Director of Production and Installations
                    </p>
                    <p className="m-0">
                      <span className="text-gray-500 font-semibold">
                        New York City Buildings General Contractor
                      </span>
                      <br />
                      Mike is an accomplished Ironworker who is an active member of the Miami Local Union 272. As one of the first installers of unitized curtain wall, he has accumulated extensive knowledge during his active career of 40 years working in curtain wall facades. Mike acquired vast experience during his work in other well-known façade companies and spans miscellaneous materials such as metal, glazing, and stone. This includes Fabrication, Supervision, Management, Equipment, Quality Control, Safety, and Labor Negotiations. His excellent performance assists him in an effective organization of all the field operations. Mike DeHarde is also a former owner of Gamma North America.
                      </p>
                  </>
                )}
                {founder.node.name === "roberto" && (
                  <>
                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      Vice President
                    </p>
                    <h2 className="m-0">Roberto Banbanaste</h2>

                    <p className="m-0 uppercase tracking-widest font-semibold text-green-600">
                      Director of Engineering and Design
                    </p>
                    <p className="m-0">
                      <span className="text-gray-500 font-semibold">
                        Bachelor’s Degree in Civil Engineering
                      </span>
                      <br />
                      Engineer, Designer, highly motivated and dedicated, with
                      more than 30 years of experience in Construction, of which
                      the last 21 years have been devoted exclusively to the
                      Design, Engineering, Management, Quality Control and
                      Manufacturing of custom facade systems, acquiring his
                      impressive experience conducting well known projects as
                      part of the Engineering and Design Teams of some of the
                      biggest Curtain Wall companies located in South Florida.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FounderCards
